import { Inject, Injectable } from '@angular/core';
import { CalcExpression } from '../shared/calculation-expression';
import { GLOBAL, IGlobalSettings } from '../shared/global-settings';
import { OrgCalculationTransform } from './org-calculation-models';


@Injectable({
  providedIn: 'root',
})
export class ManagementFeesService {
  config?: OrgCalculationTransform[];
  /** this report is outputting to the console so that splits can be checked */
  debugSplitsReport = '';
  /** by default splits should balance,  The rules in here are the execeptions of what expense - revenue should equal */
  splitBalanceExceptions: { [orgCode: string]: number } = {};

  constructor(@Inject(GLOBAL) private globalSettings: IGlobalSettings) {
    this.initConfig();
  }

  private initConfig() {
    // This is where you would go to manually hardcode any sort of management fee splits for communities.

    const stdRevCalc = '[499900] - [439800]';
    // one off calulation of revenue for sg
    //const sgRevCalc =
    //  '[499900] - [439800] - [424140] - [424200] - [424400] - [424401] - [424500] - [424501] - [424502] - [424503] - [424504] - [425500]';
    // expense splits
    const stdCoreExpSplit: AccountSplit[] = [{ acct: '516100', pct: .0325 }, { acct: '516300', pct: .0075 }];
    const stdCoreExpSplit2: AccountSplit[] = [{ acct: '516100', pct: .03 }, { acct: '516300', pct: .01 }]; 
    const conventionalExpSplit: AccountSplit[] = [{ acct: '516100', pct: .03 }];
    const stdPtnrExpSplit: AccountSplit[] = [{ acct: '516100', pct: .04 }];
    const w10ExpSplit: AccountSplit[] = [{ acct: '516100', pct: .03 }];
    const asExpSplit: AccountSplit[] = [{ acct: '516100', pct: .025 }, { acct: '516300', pct: .015 }];

    // revenue splits
    const coreStdRevSplit: AccountSplit[] = [{ org: 'reginc', acct: '411000', pct: .0325 }, { org: 'ce', acct: '446001', pct: .0075 }]; //NCore
    const coreStdRevSplit4: AccountSplit[] = [{ org: 'reginc', acct: '411000', pct: .03 }, { org: 'ce', acct: '446001', pct: .01 }]; //NCore
    const coreRevSplit2: AccountSplit[] = [{ org: 'reginc', acct: '411100', pct: .0325 }, { org: 'ce', acct: '446001', pct: .0075 }]; //PCore
    const coreRevSplit5: AccountSplit[] = [{ org: 'reginc', acct: '411100', pct: .03 }, { org: 'ce', acct: '446001', pct: .01 }]; //PCore      
    const coreRevSplit3: AccountSplit[] = [{ org: 'reginc', acct: '411100', pct: .025 }, { org: 'ce', acct: '446001', pct: .015 }];
    
    const blvRevSplit: AccountSplit[] = [{ org: 'reginc', acct: '411100', pct: .03 }];
    const gatRevSplit: AccountSplit[] = [{ org: 'reginc', acct: '413000', pct: .03 }];

    const ptnrStdRevSplit: AccountSplit[] = [{ org: 'reginc', acct: '413000', pct: .0325 }, { org: 'ce', acct: '446001', pct: .0075 }];
    const ptrconventionalRevSplit: AccountSplit[] = [{ org: 'reginc', acct: '413000', pct: .03 }];

    const w10RevSplit: AccountSplit[] = [{ org: 'reginc', acct: '413000', pct: .0325 }, { org: 'ce', acct: '446001', pct: .0050 }];

    // Usually partners will be LagNone, and core properties will be lagged by one month due to cash receipts and possibility of backdating in December
    const lagOneMonth = 1;
    const lagNone = 0;

    this.config = [
      this.createPropertyFeeConfig('ap', stdRevCalc, stdCoreExpSplit, coreStdRevSplit, lagOneMonth),
      this.createPropertyFeeConfig('ar', stdRevCalc, stdCoreExpSplit, coreStdRevSplit, lagOneMonth),
      this.createPropertyFeeConfig('as', stdRevCalc, asExpSplit, coreRevSplit3, lagOneMonth),
      this.createPropertyFeeConfig('atl', stdRevCalc, stdCoreExpSplit, coreRevSplit2, lagOneMonth),
      this.createPropertyFeeConfig('ba', stdRevCalc, stdCoreExpSplit, coreStdRevSplit, lagOneMonth),
      this.createPropertyFeeConfig('bc', stdRevCalc, stdCoreExpSplit, coreStdRevSplit, lagOneMonth),
      this.createPropertyFeeConfig('blv', stdRevCalc, stdCoreExpSplit, coreRevSplit2, lagOneMonth),
      this.createPropertyFeeConfig('bv', stdRevCalc, stdCoreExpSplit, coreStdRevSplit, lagOneMonth),
      this.createPropertyFeeConfig('bw', stdRevCalc, stdCoreExpSplit, coreStdRevSplit, lagOneMonth),
      this.createPropertyFeeConfig('cb', stdRevCalc, stdCoreExpSplit, coreRevSplit2, lagOneMonth),
      this.createPropertyFeeConfig('ch', stdRevCalc, stdCoreExpSplit, coreStdRevSplit, lagOneMonth),      
      this.createPropertyFeeConfig('cm-cs', stdRevCalc, stdCoreExpSplit, coreRevSplit2, lagOneMonth),
      this.createPropertyFeeConfig('cm-cv', stdRevCalc, stdCoreExpSplit, coreStdRevSplit, lagOneMonth),
      this.createPropertyFeeConfig('cm-pks', stdRevCalc, stdCoreExpSplit, coreStdRevSplit, lagOneMonth),
      this.createPropertyFeeConfig('cn', stdRevCalc, stdPtnrExpSplit, ptnrStdRevSplit, lagNone),
      this.createPropertyFeeConfig('col', stdRevCalc, stdCoreExpSplit2, coreStdRevSplit4, lagOneMonth),
      this.createPropertyFeeConfig('cp', stdRevCalc, stdCoreExpSplit, coreStdRevSplit, lagOneMonth),
      this.createPropertyFeeConfig('cpm', stdRevCalc, stdCoreExpSplit, coreRevSplit2, lagOneMonth),
      this.createPropertyFeeConfig('cs', stdRevCalc, stdCoreExpSplit, coreRevSplit2, lagOneMonth),
      this.createPropertyFeeConfig('cw', stdRevCalc, stdCoreExpSplit, coreStdRevSplit, lagOneMonth),
      this.createPropertyFeeConfig('en', stdRevCalc, stdCoreExpSplit, coreRevSplit2, lagOneMonth),
      this.createPropertyFeeConfig('fg', stdRevCalc, stdCoreExpSplit, coreStdRevSplit, lagOneMonth),
      this.createPropertyFeeConfig('fp', stdRevCalc, stdCoreExpSplit, coreStdRevSplit, lagOneMonth),
      this.createPropertyFeeConfig('gat', stdRevCalc, conventionalExpSplit, gatRevSplit, lagNone),
      this.createPropertyFeeConfig('gg', stdRevCalc, stdCoreExpSplit, coreStdRevSplit, lagOneMonth),
      this.createPropertyFeeConfig('gp', stdRevCalc, stdCoreExpSplit, coreRevSplit2, lagOneMonth),
      this.createPropertyFeeConfig('hh', stdRevCalc, stdCoreExpSplit2, coreStdRevSplit4, lagOneMonth),
      this.createPropertyFeeConfig('hm', stdRevCalc, stdCoreExpSplit2, coreRevSplit5, lagOneMonth),
      this.createPropertyFeeConfig('hv', stdRevCalc, stdCoreExpSplit, coreStdRevSplit, lagOneMonth),
      this.createPropertyFeeConfig('iq', stdRevCalc, stdCoreExpSplit, coreRevSplit2, lagOneMonth),
      this.createPropertyFeeConfig('la', stdRevCalc, stdCoreExpSplit, coreStdRevSplit, lagOneMonth),
      this.createPropertyFeeConfig('ld', stdRevCalc, stdCoreExpSplit, coreRevSplit2, lagOneMonth),
      this.createPropertyFeeConfig('lux', stdRevCalc, stdCoreExpSplit, coreRevSplit2, lagOneMonth),
      this.createPropertyFeeConfig('lv', stdRevCalc, stdCoreExpSplit, coreStdRevSplit, lagOneMonth),
      this.createPropertyFeeConfig('mp', stdRevCalc, stdCoreExpSplit2, coreStdRevSplit4, lagOneMonth),
      this.createPropertyFeeConfig('mw', stdRevCalc, stdCoreExpSplit, coreStdRevSplit, lagOneMonth),
      this.createPropertyFeeConfig('og', stdRevCalc, stdCoreExpSplit, coreStdRevSplit, lagOneMonth),
      this.createPropertyFeeConfig('om', stdRevCalc, stdCoreExpSplit, coreRevSplit2, lagOneMonth),
      this.createPropertyFeeConfig('pa', stdRevCalc, stdPtnrExpSplit, ptnrStdRevSplit, lagNone),
      this.createPropertyFeeConfig('pg', stdRevCalc, stdCoreExpSplit2, coreStdRevSplit4, lagOneMonth),
      this.createPropertyFeeConfig('pl', stdRevCalc, stdCoreExpSplit, coreRevSplit2, lagOneMonth),
      this.createPropertyFeeConfig('pr', stdRevCalc, stdCoreExpSplit, coreRevSplit2, lagOneMonth),
      this.createPropertyFeeConfig('pt', stdRevCalc, stdPtnrExpSplit, ptnrStdRevSplit, lagNone),
      this.createPropertyFeeConfig('rf', stdRevCalc, stdCoreExpSplit, coreRevSplit2, lagOneMonth),
      this.createPropertyFeeConfig('sor', stdRevCalc, stdCoreExpSplit2, coreRevSplit5, lagOneMonth),
      this.createPropertyFeeConfig('st', stdRevCalc, stdCoreExpSplit, coreStdRevSplit, lagOneMonth),
      this.createPropertyFeeConfig('tc', stdRevCalc, stdCoreExpSplit, coreStdRevSplit, lagOneMonth),
      this.createPropertyFeeConfig('tg', stdRevCalc, stdCoreExpSplit2, coreStdRevSplit4, lagOneMonth),
      this.createPropertyFeeConfig('tl', stdRevCalc, stdCoreExpSplit, coreRevSplit2, lagOneMonth),
      this.createPropertyFeeConfig('tp', stdRevCalc, stdCoreExpSplit, coreStdRevSplit, lagOneMonth),
      this.createPropertyFeeConfig('w10', stdRevCalc, w10ExpSplit, w10RevSplit, lagNone),
    ];

    console.log('ManagementFeesService::debugSplitsReport');
    console.log(this.debugSplitsReport);
  }


  /**
   * Creates an OrgCalcRule mapping assignments from expense and revenue splits
   * @param orgCode The source org code
   * @param revenueCalulationExpr Calculation to get REVENUE variable
   * @param expenseFrom Splits that are transformed into assignment expressions and get the negated REVENUE value
   * @param revenueTo Splits that are transformed into asignement expressions age get the value of REVENUE
   * @param sourceLag how much should the source values be lagged by (1 means that source values are pulled from the month earlier)
   */
  private createPropertyFeeConfig(orgCode: string,
    revenueCalulationExpr: string,
    expenseFrom: AccountSplit[],
    revenueTo: AccountSplit[],
    sourceLag: number = 0) {

    this.checkSplitsBalance(orgCode, expenseFrom, revenueTo);
    this.debugSplitsReport += [orgCode,
      (expenseFrom.length > 0) ? expenseFrom[0].pct : null,
      (expenseFrom.length > 0) ? expenseFrom[0].acct : null,
      (expenseFrom.length > 1) ? expenseFrom[1].pct : null,
      (expenseFrom.length > 1) ? expenseFrom[1].acct : null,
      (expenseFrom.length > 2) ? expenseFrom[2].pct : null,
      (expenseFrom.length > 2) ? expenseFrom[2].acct : null,
      (revenueTo.length > 0) ? revenueTo[0].pct : null,
      (revenueTo.length > 0) ? revenueTo[0].acct : null,
      (revenueTo.length > 1) ? revenueTo[1].pct : null,
      (revenueTo.length > 1) ? revenueTo[1].acct : null,
      sourceLag
    ].join('\t') + '\n';

    const transform = new OrgCalculationTransform();
    transform.orgCode = orgCode;
    transform.sourceLag = sourceLag;
    transform.sourceViewName = this.globalSettings.defaultCalculationView;
    transform.sourceCalculations = [{ varName: 'REVENUE', calcExpr: new CalcExpression(revenueCalulationExpr) }];
    transform.assignmentCalculations = [
      ...expenseFrom.map(x => ({ varName: x.acct, calcExpr: new CalcExpression(`[REVENUE] * ${x.pct}`), orgCode: x.org || orgCode })),
      ...revenueTo.map(x => ({ varName: x.acct, calcExpr: new CalcExpression(`[REVENUE] * ${x.pct} * -1`), orgCode: x.org || orgCode }))
    ];

    return transform;
  }

  /**
   * By default splits should balance to 0.  This will check if that's the case.  Some exceptions may exist!
   * @param name
   * @param expenseSplits
   * @param revenueSplits
   */
  private checkSplitsBalance(orgCode: string, expenseSplits: AccountSplit[], revenueSplits: AccountSplit[]) {
    const expensePct = expenseSplits.reduce((p, c) => c.pct + p, 0);
    const revenuePct = revenueSplits.reduce((p, c) => c.pct + p, 0);

    const isBalanced = expensePct - revenuePct === (this.splitBalanceExceptions[orgCode] || 0);
    if (!isBalanced) {
      console.warn(`${orgCode} splits do not balance.  Expenses: ${expensePct}, Revenue: ${revenuePct}`);
    }
  }
}

interface AccountSplit {
  /** optional orgCode where values are applied to the split */
  org?: string;
  /** account code for split */
  acct: string;
  /** percentage for split; .04 = 4% */
  pct: number;
}


